import * as React from "react"
// @ts-ignore
import { A, Button, H2, Navbar, Container, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink, ListGroup, ListGroupItem, ListGroupItemHeading} from "@bootstrap-styled/v4"
// @ts-ignore
import BootstrapProvider from '@bootstrap-styled/provider';
import DataTable, { TableColumn } from 'react-data-table-component';

// styles
const pageStyles = {
    color: "#232129",
    padding: 96,
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 320,
}
const headingAccentStyles = {
    color: "#663399",
}
const paragraphStyles = {
    marginBottom: 48,
}
const codeStyles = {
    color: "#8A6534",
    padding: 4,
    backgroundColor: "#FFF4DB",
    fontSize: "1.25rem",
    borderRadius: 4,
}
const listStyles = {
    marginBottom: 96,
    paddingLeft: 0,
}
const listItemStyles = {
    fontWeight: 300,
    fontSize: 24,
    maxWidth: 560,
    marginBottom: 30,
}

const linkStyle = {
    color: "#8954A8",
    fontWeight: "bold",
    fontSize: 16,
    verticalAlign: "5%",
}

const docLinkStyle = {
    ...linkStyle,
    listStyleType: "none",
    marginBottom: 24,
}

const descriptionStyle = {
    color: "#232129",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 0,
    lineHeight: 1.25,
}

const docLink = {
    text: "TypeScript Documentation",
    url: "https://www.gatsbyjs.com/docs/how-to/custom-configuration/typescript/",
    color: "#8954A8",
}

const badgeStyle = {
    color: "#fff",
    backgroundColor: "#088413",
    border: "1px solid #088413",
    fontSize: 11,
    fontWeight: "bold",
    letterSpacing: 1,
    borderRadius: 4,
    padding: "4px 6px",
    display: "inline-block",
    position: "relative" as "relative",
    top: -2,
    marginLeft: 10,
    lineHeight: 1,
}

// data
const links = [
    {
        badge: true,
        text: "Assassin",
        url: "/class/assassin",
        description:
            "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
        color: "#E95800",
    },
    {
        text: "Dervish",
        url: "/class/dervish",
        description:
            "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
        color: "#1099A8",
    },
    {
        text: "Elementalist",
        url: "/class/elementalist",
        description:
            "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
        color: "#BC027F",
    },
    {
        text: "Mesmer",
        url: "/class/mesmer",
        description:
            "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
        color: "#0D96F2",
    },
    {
        text: "Monk",
        url: "/class/monk",
        description:
            "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
        color: "#8EB814",
    },
    {
        text: "Necromancer",
        url: "/class/necromancer",
        description:
            "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
        color: "#8EB814",
    },
    {
        text: "Paragon",
        url: "/class/paragon",
        description:
            "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
        color: "#8EB814",
    },
    {
        text: "Ranger",
        url: "/class/ranger",
        description:
            "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
        color: "#8EB814",
    },
    {
        text: "Ritualist",
        url: "/class/ritualist",
        description:
            "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
        color: "#8EB814",
    },
    {
        text: "Warrior",
        url: "/class/warrior",
        description:
            "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
        color: "#8EB814",
    }
]

const gwPvXWikiBaseURL = "https://gwpvx.fandom.com/wiki/Build:"

const gwBuildList = [{
        name: "A/D_Shadow_Theft_Scythe"
    }, {
        name: "A/D_Vow_of_Strength"
    }, {
        name: "A/any_Shadow_Theft_Daggers"
    }, {
        name: "A/D_Wounding_Strike"
    }]

interface gwEquipment_Armor {
    id: string;
    armor_piece: string;
    insignia: string;
    rune: string;
}

const gwEquipment_Armor: TableColumn<gwEquipment_Armor>[] = [
    {
        name: '#',
        selector: row => row.id,
    },
    {
        name: 'Armor piece',
        selector: row => row.armor_piece,
    },
    {
        name: 'Insignia',
        selector: row => row.insignia,
    },
    {
        name: 'Rune',
        selector: row => row.rune,
    },
];

const gw_Equipment_Armor_data = [
    {
        id: '1',
        armor_piece: 'Head',
        insignia: 'Nightstalker',
        rune: '+3 Critical Strikes',
    },
    {
        id: '1a',
        armor_piece: 'Head',
        insignia: 'Nightstalker',
        rune: '+2 Critical Strikes',
    },
    {
        id: '1b',
        armor_piece: 'Head',
        insignia: 'Nightstalker',
        rune: '+1 Critical Strikes',
    },
    {
        id: '1',
        armor_piece: 'Chest',
        insignia: 'Nightstalker',
        rune: '+50 Vigor',
    },
    {
        id: '1',
        armor_piece: 'Gloves',
        insignia: 'Nightstalker',
        rune: '+10 Vitae',
    },
    {
        id: '1a',
        armor_piece: 'Gloves',
        insignia: 'Nightstalker',
        rune: '+3 Dagger Mastery',
    },
    {
        id: '1',
        armor_piece: 'Pants',
        insignia: 'Nightstalker',
        rune: 'Clarity',
    },
    {
        id: '1',
        armor_piece: 'Boots',
        insignia: 'Nightstalker',
        rune: '+10 Vitae',
    },
]

interface gwEquipment_Weapon {
    type: string;
    prefix: string;
    suffix: string;
    insignia: string;
}

const gwEquipment_Weapon: TableColumn<gwEquipment_Weapon>[] = [
    {
        name: 'Prefix',
        selector: row => row.prefix,
        sortable: true,
    },
    {
        name: 'Type',
        selector: row => row.type,
        sortable: true,
    },
    {
        name: 'Insignia',
        selector: row => row.insignia,
        sortable: true,
    },
    {
        name: 'Suffix',
        selector: row => row.suffix,
        sortable: true,
    },
];

const gw_Equipment_Weapon_data = [
    {
        type: 'Scythe',
        prefix: 'Vampiric',
        suffix: '+5 Armor / +30 Vigor',
        insignia: '15^Enchanted',
    }, {
        type: 'Scythe',
        prefix: 'Zealous',
        suffix: '+5 Armor / +30 Vigor',
        insignia: '15^Enchanted',
    }, {
        type: 'Scythe',
        prefix: 'Vampiric',
        suffix: '+20% Enchantments',
        insignia: '15^Enchanted',
    }, {
        type: 'Scythe',
        prefix: 'Zealous',
        suffix: '+20% Enchantments',
        insignia: '15^Enchanted',
    }, {
        type: 'Dagger',
        prefix: 'Vampiric',
        suffix: '+5 Armor / +30 Vigor',
        insignia: '15^Enchanted',
    }, {
        type: 'Dagger',
        prefix: 'Zealous',
        suffix: '+5 Armor / +30 Vigor',
        insignia: '15^Enchanted',
    },
]
// markup
const Assassin = () => {
    const [isOpen, setState] = React.useState(false);

    return (
        <BootstrapProvider style={pageStyles}>
            <Navbar color="faded" light toggleable="lg">
                <Container>
                    <div className="d-flex justify-content-between">
                        <NavbarBrand tag={A} to="javascript:;">Class Selection</NavbarBrand>
                        <NavbarToggler onClick={() => setState( !isOpen )} />
                    </div>
                    <Collapse navbar isOpen={isOpen}>
                    <Nav navbar className="mr-auto">
                        <NavItem>
                            <NavLink href="/">Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/class/assassin" active>Assassin</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/class/dervish">Dervish</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/class/elementalist">Elementalist</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/class/Mesmer">Mesmer</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/class/monk">Monk</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/class/necromancer">Necromancer</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/class/paragon">Paragon</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/class/ranger">Ranger</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/class/ritualist">Ritualist</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/class/warrior">Warrior</NavLink>
                        </NavItem>
                    </Nav>
                    </Collapse>
                </Container>
            </Navbar>

            <main>
                <title>Assassin - Guild Wars Guide</title>
                <Container>
                    <H2 style={headingStyles}>
                        Assassin
                    </H2>
                    <ListGroup>
                        <ListGroupItemHeading>Top Builds</ListGroupItemHeading>
                        {gwBuildList.map(gwBuild => (
                            <ListGroupItem key={gwBuild.name} >
                                <a href={gwPvXWikiBaseURL+gwBuild.name} target={"_blank"}>{gwBuild.name.replaceAll("_", " ")}</a>
                            </ListGroupItem>
                        ))}
                    </ListGroup>

                    <DataTable
                        columns={gwEquipment_Armor}
                        data={gw_Equipment_Armor_data}
                        title={"Optimum Armor"}
                        highlightOnHover
                        striped
                    />

                    <DataTable
                        columns={gwEquipment_Weapon}
                        data={gw_Equipment_Weapon_data}
                        title={"Weapon's Bed"}
                        highlightOnHover
                        striped
                    />
                </Container>
            </main>
        </BootstrapProvider>
    )
}

export default Assassin
